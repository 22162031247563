<template>
  <template v-if="isMobile">
    <TsRow class="mx-4 justify-between items-center py-2">
      <TsTypography @click="clearAll" appendClass="text-primary cursor-pointer" :data-testid="dataTestid + '-clear-all'">
        Clear
      </TsTypography>
      <TsButton :disabled="compareStore.checkedProduts.length < 2" @click="handleCompareProducts" :data-testid="dataTestid + '-button'">
        Compare ({{ items.length }})
      </TsButton>
    </TsRow>
  </template>
  <template v-else>
      <TsRow class="lg:max-w-screen-xl mx-auto justify-between items-center py-2">
      <TsColumn append-class="mx-4">
        <TsRow class="gap-x-4">
          <div v-for="item in items" :key="item.id" class="w-16 h-16 relative">
            <slot name="item" :item="item">
              <div class="absolute -right-2 -top-2 rounded-full flex cursor-pointer text-natural-grey"
                :data-testid="dataTestid + '-cross'" @click="removeItem(item)">
                <TsIcon name="mdi:cross-circle-outline" size="24" />
              </div>
              <TsRow append-class="m-0 bg-white border-2 border-gray-200 rounded-lg p-2">
                <NuxtImg :src="item.image" :data-testid="dataTestid + '-image'" />
              </TsRow>
            </slot>
          </div>
        </TsRow>
      </TsColumn>
      <TsColumn append-class="max-w-max">
        <TsRow append-class="m-0 items-center gap-8">
          <TsTypography @click="clearAll" appendClass="text-primary cursor-pointer" weight="semibold"
            :data-testid="dataTestid + '-clear-all'">
            Clear
          </TsTypography>
          <TsButton :disabled="compareStore.checkedProduts.length < 2" @click="handleCompareProducts" :data-testid="dataTestid + '-button'">
            Compare ({{ items.length }})
          </TsButton>
        </TsRow>
      </TsColumn>
    </TsRow>
    </template>
</template>

<script setup lang="ts">

defineOptions({
  inheritAttrs: false,
});

const emit = defineEmits(['remove-item', 'clear-all'])
const compareStore = useCompareStore();
type Props = {
  items: object[];
  appendClass?: string;
  dataTestid?: string;
}

const props = withDefaults(defineProps<Props>(), {
});

const { isMobile } = useDevice();

function removeItem(item: object) {
  emit('remove-item', item);
}

function clearAll() {
  emit('clear-all');
  compareStore.freezedProductCode = undefined;
}

function handleCompareProducts() {
  compareStore.enableExpand = true;
}
</script>
